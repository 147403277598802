@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  /* Colors */
  --green: #83E356;
  --green-hover: #a8f086;
  --blue-light: #297FDB;
  --blue-light-hover: #448ff1;
  --blue-dark: #061380;
  --blue-deep: #040a3d;
  
  /* Neutrals */
  --black: #1b1b1b;
  --black-hover: #41414d;
  --silver: #f5f7f9;
  
  /* Effects */
  --blue-gradient: linear-gradient(144deg, rgba(6,19,128,1) 0%, rgba(4,10,61,1) 97%);
  --box-shadow: 0px 0px 16px #b8b8b8;
  --shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  /* Typhography */
  font-family: 'Montserrat', sans-serif;
}

html,body {
  scroll-behavior: smooth;
  overflow-x: hidden;
  width: 100vw;
}

h1 {
    font-size: 48px;
    color: white;
    font-weight: 800;
}

h2 {
    text-align: center;
    color: var(--blue-dark);
    font-family: 'Montserrat Alternates', sans-serif;
    font-weight: 700;
    font-size: 36px;
    margin: 80px 0 40px 0;;
}

h3 {
  color: white;
  font-weight: 600;
  font-size: 24px;
  transition: all 0.5s;
}

p {
  line-height: 150%;
  font-size: 16px;
  font-weight: 400;
  margin: 1em 0;
  color: var(--black);
}


button {
  font-family: 'Montserrat';
  background-color: var(--blue-light);
  color: white;
  text-align: center;
  border: none;
  border-radius: 6px;
  padding: 1em 1.3em;
}

button:hover {
  cursor: pointer;
  background-color: var(--blue-light-hover);
}

section {
  margin-top: 3rem;
  padding: 0 12%;
}

a {
  text-decoration: none;
}