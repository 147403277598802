.project_card {
    box-sizing: border-box;
    width: 340px ;
    margin-top: 8px;
    border-radius: 8px;
    box-shadow: 0px 0px 16px #b8b8b8;
    padding-bottom: 16px;
    height: 440px;

    img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 8px;
        margin-bottom: 16px;
    }

    button {
        font-size: 15px;
        padding: 16px 24px;
        color: black;
        background-color: var(--silver);
        border: none;
        border-radius: 8px;
        margin: 0px 0 0 60%;
        height: auto;
        transition: all 0.08s;
    }

    button:hover {
        color: white;
        background-color: var(--green);
        border: none;
    }
}

.project_text {
    padding: 0 32px;
    height: 38%;

    h5 {
        color: var(--blue-light);
        font-size: 12px;
    }

    h4 {
        font-size: 20px;
        color: var(--black);
        font-weight: 600;
        margin: 8px 0;
    }

    p {
        text-align: start;
        font-size: 16px;
        margin: 0px;
        line-height: 1.4;
    }
}

button .off {
    display: none;
}


